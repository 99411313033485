import "./src/components2/layout.css"

import React from "react"
import { AnimatePresence } from "framer-motion"

import "@fontsource/anton"
import "@fontsource/epilogue"


export const wrapPageElement = ({ element }) => (
  <AnimatePresence 
  exitBeforeEnter  
  initial={false}>{element}</AnimatePresence>
)
