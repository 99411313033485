import styled from 'styled-components';

export const BlogLeftColumn = styled.div`

@media screen and (max-width: 670px){
    width: 100%; 
    padding-left: 0; 
    padding-right: 0; 
}

width:100%; 
flex: 0 0 auto; 
display:flex; 
flex-direction: column; 
padding-right: 1rem; 
font-size:1.25rem


`

export const BlogRightColumn = styled.div`

@media screen and (max-width: 670px){
    width: 100%; 
    padding-left: 0; 
    padding-right: 0; 
}

width:100%; 
flex: 0 0 auto; 
display:flex; 
flex-direction: column; 
font-size:1.25rem

`

export const ContentFlexWrapper =styled.div`
display:flex;
@media screen and (max-width: 670px){
    flex-direction: column
}
flex-direction: column;

`